$color-primary : #F6F6F6;
$color-secondary : #303551;
$color-special : #F2A90B;
$color-tertiary : #FFFFFF;
$color-lighter-grey-background: #C4C4C4;
$color-light-text: #000000;
$color-medium-text: #8C8C8C;
$color-dark-text: #000000;
$color-action: #EAEEF3;
$color-action-hover: #90b8f8;
$color-success:#158302;
$color-danger:#9e1e07;
$color-rewind: #FF6A00;
$color-green: #2baf1a;

.profile-card {
  background-color: $color-primary;
  // box-shadow: 0 0.2rem 0 0  $color-medium-text;
  font-size: smaller;
  padding: 1rem 0.5rem;
  margin: 0 1rem;
  cursor: pointer; 
  
}
  
  #profile {
    margin: auto;
    padding: 0;
    width: 95%;
    max-width: 700px;
    background-color: $color-tertiary;
    border-radius: 1rem;
    box-shadow: 0 5px 0 0  $color-medium-text;
    align-items: center;
    justify-content: center;
  }
  
  #profile-input {
    width: 85%;
    border-radius: 0.5rem;
    border: solid 0.1rem $color-dark-text;
    padding: 1vh 1.5vw 1vh 1.5vw;
    // font-family: $font-stack-lato;
    color: $color-dark-text;
    font-size: larger;
    margin: 0.5rem 0 0.5rem 0;
  }
  
  #profile-input::placeholder {
    color: $color-dark-text;
  }
  
  
  #profile .profile-image {
    // background-image: url(./thumbnail-module-0.jpg);
    background-color: $color-secondary;
    position:relative;
    width: 100%;
    height:250px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
    width: 100%;
    // height: fit-content;
  }
  
  .profiles { 
    margin-top: 170px;
  }
  
  .profile-image h4 {
    position: relative;
    top: 70%;
    z-index: 1;
  }
  
  .profile-img {
    background-image: url(https://peppercornsearch.s3.us-east-2.amazonaws.com/profile/man3.jpg);
    position:relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width:100%;
    height: 200px;
    overflow: hidden;
  }
  
  .hide {
    display: none;
  }
  
  .profile-img:hover + .hide {
    display: none;
  }
  
  .helptext {
    color: $color-success;
    // text-decoration: $color-success;
    cursor: pointer;
  }
  
  .helptext:hover {
    text-decoration: underline;
  }
  
  .forgottext {
    color: $color-success;
    cursor: pointer;
  }
  
  .forgottext:hover {
    color: $color-success;
    text-decoration: underline $color-success;
  }

//   Accordion

.accordion-card {
    max-width: 100%;
    border-radius: 1rem;
  }
  
  .accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    margin: auto auto;
    padding: 0.9rem 1.5rem 0.9rem 1rem;
  }
  
  .accordion-title-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    margin: auto auto;
    padding: 0.3rem 0.3rem;
  }
  
  .accordion-title:hover {
    // opacity: 80%;
  }
  
  .accordion-title,
  .accordion-content {
    align-items: center;
  }
  
  .accordion-content {
    background-color: $color-primary;
    padding-top: 1rem;
  }
  
  .accordion-card svg {
    transition: .3s transform ease-in-out;
  }
  .accordion-card svg {
    transform: rotate(0deg);
  }
  
  .accordion-card .isActive svg {
    transition: .3s transform ease-in-out;
  }
  .accordion-card .isActive  svg {
    transform: rotate(180deg);
  }
  
  .accordion-item {
    width: 100%;
    height: fit-content;
  }
  
.selected {
  border: solid 0.1rem $color-dark-text;
}


li.no-bullets {
  list-style-type: none;
}

.errorMsg {
  // max-width: inherit;
  color: $color-danger;
  text-align: start;
  font-size: small;
}

.errorslist {
  display: flex;
  flex-flow: column wrap;
  text-align: start;
}

.errorchild {
  width: 40%;
  height: 40%;
  padding: 0.2rem 0;
  text-align: start;
}
.errorfull{
  width: 75%;
  height: 40%;
  padding: 0.2rem 0;
  text-align: start;
}

