.App {
  padding: 20px;
}

.App-header {
  background-color: #F2A90B;
  height: 50px;
  padding: 10px;
  color: rgb(0, 0, 0);
}

.App-intro {
  font-size: small;
  margin: 0;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
