$color-secondary: #303551;

.sidePanel {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: $color-secondary;
  color: white;
  width: 30vw;
  z-index: 99;
  transform: translateX(0%);
  transition: transform 0.3s ease-in-out;

  &.active {
    transform: translateX(0);
  }

  @media (max-width: 768px) {
    width: 100vw;
  }

  .panelContent {
    padding: 1rem;

    .jobTitle {
      font-size: 1.3rem;
      margin: 1rem 0;
    }

    .inputFeed {
      margin: 1.1rem 0;
    }

    .inputFieldContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .editIcon {
      margin-left: 0.5rem;
      cursor: pointer;
      color: $color-secondary;

      &:hover {
        color: $color-secondary;
      }
    }

    .stylishInput {
      border: 1px solid #ccc;
      border-radius: 0.25rem;
      padding: 0.5rem;
      font-size: 1rem;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: $color-secondary;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
  }
}

.inputFeed {
  margin: 1.1rem 0.5rem;
}

.wrapInputControl {
  display: flex;
  flex-direction: column;
}

.stylishInput {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: $color-secondary;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.editIcon {
  cursor: pointer;
  margin-left: 0.5rem;
  color: $color-secondary;

  &:hover {
    color: $color-secondary;
  }
}