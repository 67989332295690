$color-primary : #F6F6F6;
$color-secondary : #303551;
$color-special : #F2A90B;
$color-tertiary : #FFFFFF;
$color-lighter-grey-background: #C4C4C4;
$color-light-text: #000000;
$color-medium-text: #8C8C8C;
$color-dark-text: #000000;
$color-action: #EAEEF3;
$color-action-hover: #90b8f8;
$color-success:#158302;
$color-danger:#9e1e07;
$color-rewind: #FF6A00;
$color-green: #2baf1a;

body {
  line-height: normal;

  // img {
  //   width: 32px;
  //   height: 32px;
  //   border-radius: 50%;
  // }

  .table {
    margin-bottom: 0;
    margin: auto;
    width: 80%;

    td {
      width: 14.285714%;
      height: calc((100vh - 100px) / 5);

      &.grey-background {
        background-color: #F5F5F5;
      }

      &:hover {
        cursor: pointer;
      }

      .events-container {
        position: relative;
        width: 100%;
        height: calc(100% - 18px);
        overflow: auto;

        .events-wrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          

          .event-summary {
            font-size: 12px;

            .bullet {
              font-size: 16px;
            }

            .event-name {
              font-weight: 550;
            }
          }
        }
      }
    }

    // @import "media-query";
  }

  .container {
    margin-top: 16px;

    h3 {
      margin-bottom: 16px;
    }
  }
}


// SideNavBar


html,
body,
body > div { /* the react root */
  margin: 0;
  padding: 0;
  height: 100%;
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: $color-action-hover;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 0.5em;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}
