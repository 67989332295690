// General Layout Settings
$kanban-background: #e0e1e6;
$kanban-column-width: 300px;
$card-background: #ffffff;
$card-hover-background: #f0f0f0;
$card-shadow: rgba(0, 0, 0, 0.1);
$header-color: #333;



  
  // Row settings for horizontal display
  .kankan-container > .row {
    display: flex;
    flex-wrap: nowrap; // Prevents wrapping of columns
    min-width: calc(300px * 4 + 20px * 3); // 
  }



// Card styling
.card {
  background-color: $card-background;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $card-hover-background;
  }

  .card-body {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    .card-delete-icon{
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .card-close{
        color: #821D30;
        margin-bottom: 10px;
        cursor: pointer;
        width: auto !important;
      }
    }
    .card-text {
      line-height: 1.5;
      width: 100%;
      font-size: 17px;
      font-weight: 400;
      
    }
 
  
  }
}

.add-card-btn, .add-column-btn {
  display: inline-block;
    margin: 10px 0; // Space from the last card or the last column
    width: fit-content; // Full-width button
    background-color: #007bff; // Bootstrap primary blue
    color: white;
    border: none;
    cursor: pointer;
    transition: all 0.5s; 
    &:hover {
      background-color: #0056b3;
    }
  }
  .top-btn-card{
    margin-left: 10px;;
  }

  .kanban-container {
    width: 100%;
    overflow-x: auto;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    min-height: 800px;

    @media(max-width:1024px){
      display: block;
      grid-template-columns: repeat(1, 1fr);
      gap: 1rem;
      width: 100%;
    
    }
    .kanban-column {
      background-color: rgba(224, 225, 230, .5);
      padding: 20px;
      box-shadow: 0 2px 4px $card-shadow;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      margin-bottom: 1rem;
      min-width: 300px;
    
      h2 {
        color: $header-color;
        font-size: 24px;
        
      }
    
      // Scrollable content if cards overflow the vertical space
      overflow-y: auto;
    
      // Custom scrollbar for webkit browsers
      &::-webkit-scrollbar {
        width: 8px;
      }
    
      &::-webkit-scrollbar-track {
        background-color: $kanban-background;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: darken($kanban-background, 10%);
        border-radius: 10px;
      }
      .column-header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: baseline;
        margin-bottom: 1rem;
        margin-top: 5px;
        .edit-icon-pencil{
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          .edit-icon{
            color: #303551;
          }
        }
        .delete-icon{
          color: #821D30;
          cursor: pointer !important;
        }

      }
    }
    
  }

  
  .side-panel {
    width: 300px; /* Adjust the width as necessary */
    height: 100vh; /* Full height of the viewport */
    position: fixed; /* Stay in place when scrolling */
    right: 0; /* Align to the right of the page */
    top: 0; /* Align to the top of the page */
    background-color: #C4C4C4; /* Off white, adjust as needed */
    box-shadow: -2px 0 3px rgba(0,0,0,0.5); /* Optional: adds shadow for better separation */
    z-index: 1000; /* High z-index to ensure it stays on top */
    padding: 20px; /* Padding inside the panel */
    display: flex; /* Using flexbox for internal alignment */
    flex-direction: column; /* Stack children vertically */
    overflow-y: auto; /* Allows scrolling within the panel if content is too long */
    // add a transition for smooth animation
    transition: transform 1s ease-in-out;
}

.close-btn {
    align-self: flex-end; /* Position the close button at the end of the flex container */
    cursor: pointer; /* Indicates that the element is clickable */
    border: none; /* No border for a cleaner look */
    background: none; /* Transparent background */
    margin-bottom: 20px; /* Space below the button */
}

/* Styles for the heading and content inside the panel */
.side-panel h1 {
    margin-top: 0;
    color: #333; /* Dark text for contrast */
}

.side-panel p {
    color: #666; /* Slightly lighter text for the paragraph */
}
