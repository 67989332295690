$breakpoint-phone: 360px;
$breakpoint-tablet: 768px; 
$breakpoint-desktop: 1024px;

$color-primary : #F6F6F6;
$color-secondary : #303551;
$color-special : #F2A90B;
$color-tertiary : #FFFFFF;
$color-lighter-grey-background: #C4C4C4;
$color-light-text: #000000;
$color-medium-text: #8c8c8c;
$color-dark-text: #000000;
$color-action: #EAEEF3;
$color-action-hover: #90b8f8;
$color-success:#158302;
$color-danger:#9e1e07;
$color-rewind: #FF6A00;
$color-green: #2baf1a;

  
  .job-examples {
    .examples-row {
      display: flex;
      flex-wrap: wrap;
      gap: 0.1rem;
  
      .job-example-button {
        color: $color-secondary;
        font-size: smaller;
        border: none;
        margin-left: 1rem;
        margin-bottom: 1rem;
        cursor: pointer;
        &:hover {
          font-weight: bolder;
        }
      }
    }
  }
  
  ul {
    list-style-type: none;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  .input-feed {
    input[type="text"] {
        display: block;
        margin-top: 5px;
        margin-bottom: 15px;
        padding: 10px;
        border-radius: 15px; 
        border: 1px solid #ccc;
      }
      
  }

  button {
    display: block;
    padding: 10px 20px;
    background-color: $color-secondary;
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    margin-left: auto; // Ensures the button floats to the right
    margin-right: 0; // Ensures alignment to the right edge on all devices
  
    @media (max-width: $breakpoint-phone) {
      margin-right: 0; // Keeps the button floated right on mobile
    }
  }
  
  .additional-fields {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, max-height 0.3s ease;
  }
  
  .visible {
    opacity: 1;
    max-height: 500px; 
  }
  
  .hidden {
    opacity: 0;
    max-height: 0;
  }
  
  .input-field-container {
    position: relative;
  
    .input-field {
      display: block;  
      
    }
  
    .dropdown {
      position: absolute;
      top: 100%; 
      left: 0;
      right: 0;
      background: white;
      border: 1px solid #ddd;
      width: calc(100% - 2px); 
      max-height: 200px;
      overflow-y: auto;
      z-index: 10;
      box-shadow: 0 4px 6px rgba(0,0,0,0.1); 
      border-radius: 15px;
  
      .dropdown-item {
        margin-top: 10px;
        cursor: pointer;
        
  
        &:hover {
          width: 100%;
          height: 100%;
        }
  
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  
  .enabled-span {
    color: $color-green;
  }

  .disabled-span {
    color: $color-special;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .rotate-animation {
    animation: rotate 1s linear infinite;
  }
  